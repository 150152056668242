import PropTypes from 'prop-types'
import React from 'react'
import * as R from 'ramda'

import { LinkPrevious } from '../link'

import './query.fragment'
import * as styles from './index.module.css'

export function ProductDetails(props) {
  const { onRequestShowPricing, product, ...rest } = props
  const secureUrl = product && product.image && product.image.secure_url

  return (
    <div className={styles.details} {...rest}>
      <Gallery>
        <img alt={product.name} src={secureUrl} />
      </Gallery>

      <Meta>
        <h1 className={styles.name}>{product.name}</h1>
        {product.number && <h2 className={styles.number}>{product.number}</h2>}

        {product.description && (
          <div
            dangerouslySetInnerHTML={{ __html: product.description }}
            className={styles.description}
          />
        )}

        <p>
          <a
            className={styles.pdfLink}
            href={R.pathOr('', ['pdf', 'secure_url'], product)}
            rel="noopener noreferrer"
            target="_blank"
          >
            Download Catalog Page (PDF)
          </a>
        </p>

        {onRequestShowPricing && (
          <>
            <hr className={styles.separator} />
            <p>
              <button onClick={onRequestShowPricing}>Show Pricing</button>
            </p>
          </>
        )}

        <hr className={styles.separator} />

        <p>
          <LinkPrevious>Back to Previous</LinkPrevious>
        </p>
      </Meta>
    </div>
  )
}

ProductDetails.propTypes = {
  onRequestShowPricing: PropTypes.func,
  product: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.shape({
      secure_url: PropTypes.string.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
    number: PropTypes.string,
    pdf: PropTypes.shape({
      secure_url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  renderItem: PropTypes.func,
}

function Meta(props) {
  return <div className={styles.meta} {...props} />
}

function Gallery(props) {
  return (
    <div className={styles.gallery}>
      <div {...props} />
    </div>
  )
}
