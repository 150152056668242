/* eslint-disable jsx-a11y/no-autofocus */
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import {
  OverlayContainer,
  useModal,
  useOverlay,
  usePreventScroll,
} from '@react-aria/overlays'
import { useDialog } from '@react-aria/dialog'
import { FocusScope } from '@react-aria/focus'

import * as styles from './index.module.css'

export function Dialog(props) {
  const {
    children,
    onRequestClose,
    info,
    title,
    subtitle,
    variant = '',
  } = props

  const ref = useRef()
  const { overlayProps } = useOverlay(
    {
      isDismissable: true,
      isOpen: true,
      onClose: onRequestClose,
    },
    ref,
  )

  usePreventScroll()
  const { modalProps } = useModal()
  const { dialogProps, titleProps } = useDialog({}, ref)

  const underlayClassName = cx({
    [styles.underlay]: true,
    [styles.pricingVariant]: variant === 'pricing',
  })

  return (
    <OverlayContainer>
      <div className={underlayClassName}>
        <FocusScope contain restoreFocus autoFocus>
          <div
            {...overlayProps}
            {...dialogProps}
            {...modalProps}
            className={styles.dialog}
            ref={ref}
          >
            <h3 {...titleProps} className={styles.title}>
              {title}
            </h3>
            <h4 {...titleProps} className={styles.info}>
              {info}
            </h4>
            <h4 className={styles.subtitle}>{subtitle}</h4>

            {children}
          </div>
        </FocusScope>
      </div>
    </OverlayContainer>
  )
}

Dialog.propTypes = {
  children: PropTypes.node.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['pricing']),
}
