/* eslint-disable react/prop-types */
import * as R from 'ramda'
import React from 'react'
import { graphql } from 'gatsby'
import { useQuery, gql } from '@apollo/client'

import { MainLayout } from '../layouts/main'
import { Dialog } from '../components/dialog'
import { ProductDetails } from '../components/product-details'
import { Table } from '../components/table'
import * as styles from './product.module.css'

export const query = graphql`
  query ($productId: PostGraphile_UUID!) {
    postgres {
      product(id: $productId) {
        id
        ...ProductDetails
      }
    }
  }
`

export default function ProductTemplate(props) {
  const [pricingShown, setPricingShown] = React.useState(false)
  const { product } = (props.data && props.data.postgres) || {}

  const { data, error, loading } = useQuery(
    gql`
      query ($id: UUID!) {
        product(id: $id) {
          id
          priceList {
            sku
            price
            label
            banner
          }
        }
      }
    `,
    { variables: { id: product.id } },
  )

  const canSeePricing =
    !loading &&
    !error &&
    data &&
    data.product &&
    data.product.priceList.length >= 1

  const priceHeaderLabel = R.path(['product', 'priceList', 0, 'label'], data)
  const banner = R.path(['product', 'priceList', 0, 'banner'], data)

  const hidePricing = () => setPricingShown(false)
  const showPricing = () => setPricingShown(true)

  if (!product) {
    return null
  }

  return (
    <MainLayout>
      <ProductDetails
        product={product}
        {...(canSeePricing && {
          onRequestShowPricing: showPricing,
        })}
      />

      {canSeePricing && pricingShown && (
        <Dialog
          onRequestClose={hidePricing}
          open={pricingShown}
          title="Pricing"
          info="Effective 10/8/2021: Add a temporary materials surcharge of 10% to the price shown."
          subtitle={product.name}
          variant="pricing"
        >
          <Table>
            <Table.Head className={`${banner ? styles.header : ''}`}>
              <Table.Row>
                <Table.Header>SKU</Table.Header>
                <Table.Header>{priceHeaderLabel}</Table.Header>
              </Table.Row>
            </Table.Head>

            <Table.Body>
              {data.product.priceList.map((item, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{item.sku}</Table.Cell>
                  <Table.Cell>${item.price}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Dialog>
      )}
    </MainLayout>
  )
}
